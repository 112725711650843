import { useEffect } from "react"
import { useTheme } from "../theme"
import { Link } from "react-router-dom"
export default function FixedNav () {
      const {theme, setTheme} = useTheme()
      const root = document.getElementById('root')

      const handleChangeTheme = () => {
        setTheme('bg-white text-lightgray')
     }
      const handleChangeTheme_about = () => {
        setTheme('bg-white text-lightgray')
     }

    useEffect(() => {
        root.className = theme + ' transition-colors duration-500'
    }, [root, theme])
    return (
        <section className="p-5 bg-extra-lightgray fixed bottom-0 flex justify-between text-black text-lg w-full md:hidden">
            <div>
                {/* about us */}
                <button onClick={ handleChangeTheme_about}><Link to="/about-us">About Us</Link></button>
            </div>
            <div>
                <button> <Link to="/for-writers">For Writers</Link></button>
            </div>
            <div>
                <button onClick={ handleChangeTheme}> <Link to="/for-readers">For Readers</Link></button>
            </div>
        </section>
    )
}